import { ref, computed, nextTick } from 'vue';
import {
  getIdeaList,
} from '@/modules/task-manage/design-task/api/index';
import { usePagination } from './use-pagination';
import {
  IListItem,
  IParams,
} from '../types';
import {
  SHOW_MODE_LIST,
  SHOW_MODE_ENUM,
} from '../constant';
import { debounce } from 'lodash-es';
import {
  SORT_ENUM,
  SORT_LIST,
  ORDER_ENUM,
} from '@/modules/task-manage/design-task/constant';

export default () => {
  const mode = ref(SHOW_MODE_ENUM.IMAGE);
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination(mode);
  const tableTotal = ref(0);
  const keyword = ref('');
  const params = ref<IParams>({
    designTaskName: '',
    prop: SORT_ENUM.CREATE_TIME,
    order: ORDER_ENUM.DESC,
  });
  const finish = ref(false);
  const tableData = ref<IListItem[]>([]);
  const getList = async (current: number) => {
    try {
      const res = await getIdeaList({
        ...params.value,
        pageNum: current,
        pageSize: pageSize.value,
      });
      const newList = res.data?.list || [];
      tableData.value.push(...newList);
      tableTotal.value = Number(res.data.total);
      if (Number(res.data.total) <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  // 重新加载
  const reload = async () => {
    reqPageIndex.value = 1;
    tableData.value = [];
    getList(reqPageIndex.value);
    handleScrollToPage(1);
  };
  // 切换模式
  const handleChangeMode = (type: SHOW_MODE_ENUM) => {
    mode.value = type;
    params.value.prop = SORT_ENUM.CREATE_TIME;
    params.value.order = ORDER_ENUM.DESC;
    params.value.designTaskName = '';
    keyword.value = '';
    reload();
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  // 切换当前页
  const handleCurrentSizeChange = async (val: number) => {
    try {
      // 表格模式不需要存储加载过的数据
      if (mode.value === SHOW_MODE_ENUM.TABLE) {
        tableData.value = [];
        reqPageIndex.value = val;
        getList(val);
        handleScrollToPage(val);
        return;
      }
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getIdeaList({
            ...params.value,
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IListItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            tableTotal.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        tableData.value.push(...list);
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !tableData.value.length && !listLoading.value;
  });

  const handleChangeSort = (val: SORT_ENUM) => {
    if (val === params.value.prop) {
      params.value.order = Object.values(ORDER_ENUM).find(v => v !== params.value.order)!;
    } else {
      params.value.prop = val;
      params.value.order = ORDER_ENUM.DESC;
    }
    reload();
  };
  return {
    sortList: [...SORT_LIST],
    handleChangeSort,
    keyword,
    handleCurrentSizeChange,
    tableTotal,
    initPagination,
    pageSize,
    currentViewPage,
    params,
    tableData,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
    SHOW_MODE_LIST,
    mode,
    handleChangeMode,
  };
};
