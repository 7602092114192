import { ref, nextTick, onBeforeUnmount, Ref } from 'vue';
import { debounce } from 'lodash-es';
import {
  SHOW_MODE_ENUM,
} from '../constant';

export const usePagination = (
  mode: Ref<SHOW_MODE_ENUM>,
) => {
  const itemConfig = {
    width: 392 + 12,
    height: 427,
    header: 117 + 24,
  };
  const pageSize = ref(10);
  // 当前请求的页数
  const reqPageIndex = ref(1);
  // 当前视图聚焦页数
  const currentViewPage = ref(1);
  const handleScrollToPage = async (val: number) => {
    // 图文模式需要计算需要滚动位置
    if (mode.value === SHOW_MODE_ENUM.IMAGE) {
      await nextTick();
      const top = (val - 1) * itemConfig.height * 3 + 2 + itemConfig.header;
      window.scrollTo(0, top);
    }
    currentViewPage.value = val;
  };
  const scrollListener = debounce(() => {
    // 图文模式监听滚动位置
    if (mode.value === SHOW_MODE_ENUM.IMAGE) {
      const page = Math.ceil((window.pageYOffset - itemConfig.header) / (itemConfig.height * 3)) || 1;
      currentViewPage.value = page;
    }
  }, 50);
  const removeListener = () => {
    window.removeEventListener('scroll', scrollListener);
  };
  const createListener = () => {
    removeListener();
    window.addEventListener('scroll', scrollListener);
  };
  const initPagination = async (dom: HTMLElement) => {
    const { width } = dom.getBoundingClientRect();
    dom.setAttribute('style', `width:${width}px`);
    // 单行最多条数
    const lineCount = Math.floor(width / itemConfig.width);
    pageSize.value = lineCount * 3;
    createListener();
  };
  onBeforeUnmount(() => {
    removeListener();
  });
  return {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  };
};
