
import { defineComponent, computed } from 'vue';

export default defineComponent({
  emits: ['current-change'],
  name: 'pagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
    maxPagerCount: {
      type: Number,
      default: 10,
    },
  },
  setup(props, { emit }) {
    const maxPage = computed(() => {
      const { total, pageSize } = props;
      return Math.ceil(Number(total) / Number(pageSize));
    });
    const pager = computed(() => {
      const {
        current,
        maxPagerCount,
      } = props;
      const newPager:number[] = [];
      let sCount = 1;
      let length = maxPagerCount;
      if (
        current - Math.round(maxPagerCount / 2) > 1
      ) {
        sCount = current - Math.round(maxPagerCount / 2);
      }
      if (sCount + maxPagerCount > maxPage.value) {
        sCount = maxPage.value - maxPagerCount + 1 < 1 ? 1 : maxPage.value - maxPagerCount + 1;
      }
      length = sCount + maxPagerCount > maxPage.value
        ? maxPage.value - sCount + 1 : maxPagerCount;
      while (length > newPager.length) {
        newPager.push(sCount);
        sCount += 1;
      }
      return newPager;
    });
    const handleCurrentChange = (val: number) => {
      if (val <= 0) return;
      if (val > maxPage.value) return;
      emit('current-change', val);
    };
    return {
      maxPage,
      pager,
      handleCurrentChange,
    };
  },
});
