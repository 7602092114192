// 列表模式
export enum SHOW_MODE_ENUM {
  /** 图文模式 */
  IMAGE = '1',
  /** 表格模式 */
  TABLE = '2',
}

export const SHOW_MODE_LIST = [
  { value: SHOW_MODE_ENUM.IMAGE, icon: 'icon_tupian_unpitch' },
  { value: SHOW_MODE_ENUM.TABLE, icon: 'icon_liebiao' },
];
