
import { defineComponent, ref, nextTick, onMounted } from 'vue';
import EditDialog from './components/edit-dialog/index.vue';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';
import {
  useRouter,
  useRoute,
} from 'vue-router';
import { useAccountStore } from '@/store/account';
import {
  SHOW_MODE_LIST,
  SHOW_MODE_ENUM,
} from './constant';
import {
  ORDER_ENUM,
  SORT_ENUM,
} from '@/modules/task-manage/design-task/constant';
import useList from './composables/use-goods-list';
import CustomPagination from './components/pagination/index.vue';

export default defineComponent({
  components: {
    EditDialog,
    CustomPagination,
  },
  setup() {
    const accountStore = useAccountStore();
    const permissionConfig = usePermissionConfig();
    const goodsEl = ref<null|HTMLElement>(null);
    const {
      sortList,
      handleChangeSort,
      handleCurrentSizeChange,
      tableTotal,
      initPagination,
      pageSize,
      currentViewPage,
      params,
      tableData,
      listLoading,
      finish,
      listDisabled,
      isEmpty,
      loadMore,
      reload,
      mode,
      handleChangeMode,
      keyword,
    } = useList();
    const $router = useRouter();
    const $route = useRoute();
    const editDialogConfig = ref({
      visible: false,
      id: '',
    });
    const handleCreateBtn = () => {
      editDialogConfig.value.id = '';
      editDialogConfig.value.visible = true;
    };
    const customSearch = async () => {
      reload();
    };
    const handleSearch = () => {
      params.value.designTaskName = keyword.value;
      customSearch();
    };
    const handleItemEdit = (id: string) => {
      editDialogConfig.value.id = id;
      editDialogConfig.value.visible = true;
    };
    const handleItemDetail = (id: string) => {
      const newPage = $router.resolve({
        name: 'DesignTaskManageDetail',
        params: {
          id,
        },
      });
      window.open(newPage.href);
    };
    if ($route.params?.type === 'create') {
      handleCreateBtn();
    }
    const init = async () => {
      await nextTick();
      initPagination(goodsEl.value!);
      customSearch();
    };
    onMounted(() => {
      init();
    });
    return {
      ORDER_ENUM,
      SORT_ENUM,
      sortList,
      handleChangeSort,
      handleSearch,
      keyword,
      goodsEl,
      handleCurrentSizeChange,
      tableTotal,
      pageSize,
      currentViewPage,
      params,
      tableData,
      listLoading,
      finish,
      listDisabled,
      isEmpty,
      loadMore,
      reload,
      handleChangeMode,
      mode,
      accountStore,
      permissionConfig,
      handleItemDetail,
      handleItemEdit,
      customSearch,
      handleCreateBtn,
      editDialogConfig,
      SHOW_MODE_LIST,
      SHOW_MODE_ENUM,
    };
  },
});
